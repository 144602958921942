import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
)
IndexPage.Layout = Layout
export default IndexPage
